import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { FloatingLabel, Form, Row,Table } from 'react-bootstrap';
import Puff from 'react-loading-icons/dist/esm/components/puff';
import Switch from '@mui/material/Switch';
import Swal from 'sweetalert2';
import { FaEdit } from 'react-icons/fa';
function RecievePayment() {

    const [manualdate,setManualDate]=useState('');
    const [isClick,setIsClick]=useState(false);
    const [salesman,setSalesman]=useState([]);
    const [defaultsalesman,setDefaultSalesman]=useState(0);
    const [note,setNote]=useState('');
    const [amount,setAmount]=useState(0);
    const [sector,setSector]=useState([]);
    const [defaultsector,setDefaultSector]=useState(0);
    const [subsector,setSubSector]=useState([]);
    const [defaultsubsector,setDefaultSubSector]=useState(0);
    const [customer,setCustomer]=useState([]);
    const [defaultcustomer,setDefaultCustomer]=useState(0);
    const [distributer,setDistributer]=useState([]);
    const [defaultdistributer,setDefaultDistributer]=useState(0);
    const [pbalance,setPbalance]=useState(0);
    const label = { inputProps: { 'aria-label': 'Size switch demo' } };
const [check,setChecked]=useState(true);
const [data,setData]=useState([]);

const [currentPage, setCurrentPage] = useState(1);
const [itemsPerPage, setItemsPerPage] = useState('');
const [serialStart, setSerialStart] = useState('');

const [totalPages, setTotalPages] = useState(1);

const [searchQuery,setSearchQuery]=useState('');
const [updatedid,setUpdatedId]=useState('');
const [iseditClick,setIsEditClick]=useState('');
useEffect(() => {
  setSerialStart((currentPage - 1) * itemsPerPage + 1);
}, [currentPage, itemsPerPage]);

    useEffect(()=>{
        axios.get('/sanctum/csrf-cookie').then(response => {
      axios.get(`/api/getsalesman`).then(res=>{
        setDefaultSalesman(res.data.salesman[0]['id']);
        setSalesman(res.data.salesman);
      
      });
      
      axios.get(`/api/getdistributers`).then(res=>{
        setDistributer(res.data.distributers);
      
      });
      

      axios.get(`/api/getsectors`).then(res=>{
        setSector(res.data.sectors);
        setDefaultSector(res.data.sectors[0]['id']);
      
      });
     
      
        }); },[]);
       
        useEffect(() => {
            if (defaultsector) {
              
              axios.get(`/api/getsubsectors/${defaultsector}`)
              .then(response => {
                setSubSector(response.data.subsectors);
              })
              .catch(error => {
                console.error(error);
              });
            }
           
          },[defaultsector]);

          useEffect(()=>{
  
            if(defaultsubsector){
            
            
              axios.get(`/api/getshop/${defaultsubsector}`)
              .then(response => {
                
            
             
            
                const sortedShpos = [...response.data.shop].sort((a, b) => a.name.localeCompare(b.name));
                setCustomer(sortedShpos);
              
             
           
              })
              .catch(error => {
                console.error(error);
              });
            }
            },[defaultsubsector])


    const handleSubmit=(e)=>{
        e.preventDefault();
        setIsClick(true);
        const data={
        'ppcell':check?defaultcustomer:defaultdistributer,
        'manualdate':manualdate,
       'note':note,
       'amount':amount,
        'salesmanid':defaultsalesman
        
        }
       // console.log(check?defaultcustomer:defaultdistributer);
if(data.manualdate===''){


    Swal.fire({
        position: 'top-end',
        icon: 'info',
        title: 'Select Date ',
        showConfirmButton: false,
        timer: 1500
      })

setIsClick(false);

return false;


   
}


        axios.post('api/postdeposit',data).then(res=>{
if(res.data.status===200){
  Swal.fire({
    position: 'top',
    icon: 'success',
    title: res.data.msg,
    showConfirmButton: false,
    timer: 1500
  })
}else{
  Swal.fire({
    position: 'top',
    icon: 'error',
    title: res.data.msg,
    showConfirmButton: false,
    timer: 1500
  })
}
           
setIsClick(false);



        })
      
        
        }


        useEffect(() => {
    setIsClick(true);
            const data={
              salesmanid:defaultsalesman,
              ppcell:check?defaultcustomer:defaultdistributer,
              }
              //console.log(defaultsalesman);
             
              if(data.ppcell>0){
                axios.post('/api/fetchprebalance',data).then(res=>{
                  //console.log(res.data);
                 setPbalance(res.data);
//console.log(res.data);
                  setIsClick(false);
                  });
              }else{
        
                setPbalance(0);
              }
             
        
          }, [defaultcustomer,defaultdistributer,check,defaultsalesman])


       
useEffect(()=>{
  setIsClick(true);
  axios.get(`api/recievingbalancetable?page=${currentPage}&search=${searchQuery}`).then(res=>{
setItemsPerPage(res.data.per_page);
    setData(res.data.data);
    setTotalPages(res.data.last_page);

    
          })
          setIsClick(false);
},[searchQuery,currentPage,updatedid])

const handleEdit=(id,e,index)=>{
 e.preventDefault();
setUpdatedId(id);
 
setIsEditClick(index);


  const data={
    'id':id
  }
axios.post('api/geteditrecievingbalance',data).then(res=>{
if(res.data.sector_id){
  
  setDefaultSector(res.data.sector_id);
  setDefaultSalesman(res.data.salesman_id);

  setDefaultSubSector(res.data.subsector_id);

  setDefaultCustomer(res.data.user_id);
}else{

  setDefaultDistributer(res.data.user_id);
}
setManualDate(res.data.manual_date);

setAmount(res.data.recieve);



setNote(res.data.naration?res.data.naration:'');

setIsEditClick('');

})


}

const handleUpdate=()=>{
  setIsClick(true);
const data={
  'id':updatedid,
  'ppcell':check?defaultcustomer:defaultdistributer,
  'manualdate':manualdate,
 'note':note,
 'amount':amount,
  'salesmanid':defaultsalesman

}
axios.put('api/updaterecieving',data).then(res=>{
  Swal.fire({
    position: 'top-end',
    icon: 'success',
    title: 'Transection Updated',
    showConfirmButton: false,
    timer: 1500
  })
  
setUpdatedId('');
setIsClick(false);

})


}
const handleSearchChange = (event) => {

  setSearchQuery(event.target.value);
  setCurrentPage(1);
};   

  return (
    <div>
    <div className='row fadeInDown animated'>
    <center>
     <div className='col-md-6 mt-3' style={{boxShadow:'0px 0px 5px 0px #9E9E9E',padding:'18px'}}>

     <div className='co-md-11'>
{!updatedid? <h2>DEPOSIT RECIEVING BALANCE </h2>:<h2>UPDATE RECIEVING BALANCE </h2>} {parseFloat(pbalance).toFixed(2)} <hr/>
         </div>   
  
<Row className='g-2'>
<div className='col-md mt-2'>

 <FloatingLabel  label="Region Name">

   <Form.Select aria-label="Region Name" value={defaultsector} onChange={(e)=>setDefaultSector(e.target.value)}  name='sector' >
    
   {sector && sector.map(item => (
     
<option key={item.id} value={item.id}>
 {item.name} </option>))}
   </Form.Select>
 </FloatingLabel>
 </div>


<div className='col-md mt-2'>
 <FloatingLabel  label="City Name">

   <Form.Select aria-label="City Name" value={defaultsubsector}  onChange={(e)=>setDefaultSubSector(e.target.value)}  name='sector' >
   <option>Select City</option>
   {subsector && subsector.map(item => (
     
<option key={item.id} value={item.id}>
 {item.name} </option>))}
   </Form.Select>
 </FloatingLabel>
 </div>

</Row>
<Row className='g-2'>

{check?
<div className='col-md mt-4'>
 
 <FloatingLabel  label="Customer Name">

   <Form.Select aria-label="Customer Name" value={defaultcustomer} onChange={(e)=>setDefaultCustomer(e.target.value)}  name='customer' >
    <option>Select Customer</option>
   {customer && customer.map(item => (
     
<option key={item.user_id} value={item.user_id}>
 {item.name} 
 
  </option>
 
 
 ))}
   </Form.Select>
 </FloatingLabel>
 </div>
:  
<div className='col-md mt-4 animated faleInDown'>
 
 <FloatingLabel  label="Supplier Name">

   <Form.Select aria-label="Supplier Name" value={defaultdistributer} onChange={(e)=>setDefaultDistributer(e.target.value)}  name='customer' >
    
   {distributer && distributer.map(item => (
     
<option key={item.id} value={item.id}>
 {item.name} 
 
  </option>
 
 
 ))}
   </Form.Select>
 </FloatingLabel>
 </div>
}


<div className='col-md mt-4'>
 <FloatingLabel  label="Sales Associate">

   <Form.Select aria-label="Sales Associate" value={defaultsalesman} onChange={(e)=>setDefaultSalesman(e.target.value)}  name='salesman' >

   {salesman && salesman.map(item => (
   
<option key={item.id} value={item.id}>
 {item.name} </option>))}
   </Form.Select>
 </FloatingLabel>
 </div>

</Row>
<Row className='g-2'>
<div className='col-md mt-4'>

<FloatingLabel  label="Select Date" >
   <Form.Control type="date" value={manualdate} onChange={(e)=>setManualDate(e.target.value)}  name="manualdate" placeholder='Date From'   />
 </FloatingLabel>
</div>

<div className='col-md mt-4'>

<FloatingLabel  label="Enter Amount" >
   <Form.Control type="number" value={amount} onChange={(e)=>setAmount(e.target.value)}  name="amount" placeholder='Amount'  />
 </FloatingLabel>
</div>


</Row>
<Row className='g-1'>
<div className='col-md mt-4'>

<FloatingLabel  label="Put a note here" >
   <Form.Control type="text"  name="note" value={note} onChange={(e)=>setNote(e.target.value)} placeholder='comment'  />
 </FloatingLabel>
</div>
</Row>
 <div className='col-md-10 mt-4'>

 <Row className='g-2 mt-4'>

 
<div className='col-md-9'>
 
{!updatedid?
<button disabled={isClick} onClick={(e)=>handleSubmit(e)} className='btn btn-info btn-lg btn-block'>{isClick?  <Puff width={25} height={25} stroke="red"/>:'Deposit Amount'}  </button>
:
<button disabled={isClick} onClick={(e)=>handleUpdate(e)} className='btn btn-info btn-lg btn-block'>{isClick?  <Puff width={25} height={25} stroke="red"/>:'Update Amount'}  </button>

}
 <hr/>

</div>
<div className='col-md-1'>
<Switch {...label} checked={check} onChange={(e)=>setChecked(e.target.checked)} />

</div>
 </Row></div>
 </div></center>
 </div>

 <hr></hr>

<Row className='g-1'>

<div style={{width:'10px',marginLeft:'20px'}}>

<input type="text"  placeholder='Search Item' value={searchQuery} onChange={handleSearchChange} />

</div>
  
<div className='col-md-12' style={{width:'100vw',overflowX:'auto',padding:'15px'}}>

<Table striped bordered hover size="sm" className='animated faleInDown'>

<thead>
  <tr>
  <th>Sr</th>
<th>Total</th>
<th>Customer</th>
<th>Note</th>
<th>Date</th>
<th>Action</th>
  </tr>


</thead>
<tbody >
{data && data.map((item,index)=>(
  

<tr key={item.id} className='animated faleInDown'>
<td>{serialStart+index}</td>
<td>{item.total}</td>
<td>{item.customername}</td>
<td>{item.note}</td>
<td>{item.date}</td>
<td><button onClick={(e)=>handleEdit(item.id,e,index)} className='btn btn-primary btn-sm '>{iseditClick===index?<Puff height={10} width={15} stroke='white'/>: <FaEdit style={{marginBottom:'3px'}}/>}</button></td>

</tr>
))}
</tbody>

</Table>
<button
        disabled={currentPage === 1}
        onClick={() => setCurrentPage((page) => page - 1)}

        className='btn btn-danger btn-sm mr-3'
      >
        Previous
      </button>
      <span>{currentPage} of {totalPages}</span>
      <button
        disabled={currentPage === totalPages}
        onClick={() => setCurrentPage((page) => page + 1)}
        className='btn btn-danger btn-sm'
      >
        Next 
      </button>

</div>

</Row>
 </div>
  )
}

export default RecievePayment