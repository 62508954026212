
import {React,useEffect,useState} from 'react'

import {  Link, Navigate, Outlet } from 'react-router-dom'
import { useStateContext } from '../Views/Context/ContextProvider'
import { Badge, NavDropdown } from 'react-bootstrap';
import { FaBell, FaCalendarDay, FaCartPlus, FaHome, FaPowerOff, FaSignOutAlt, FaTh, FaUserSecret } from "react-icons/fa";
import Sidebar from '../Views/offCanvas/Sidebar';
import Pusher from 'pusher-js';
import { ToastContainer, toast } from 'react-toastify';
  import 'react-toastify/dist/ReactToastify.css';
  import Switch from '@mui/material/Switch';

  


function DefaultLayout() {
 
  const [productAlert,setProductAlert]=useState([]);  
const productcount=productAlert.length;
useEffect(() => {
  const pusher = new Pusher('83253a99acf7e2cb2df9', {
    cluster: 'ap2',
  });

  const channel = pusher.subscribe('productAlertChenel');
  channel.bind('productAlertEvent', (data) => {
    const object = {
      'productName': data.productName,
      'qty': data.qty,
      'id': data.id
    };

    // Use a functional update to accumulate data in the state
    setProductAlert(prevProductAlert => [...prevProductAlert, object]);

    //console.log("assigned");
  });

  return () => {
    channel.unbind(); // Unbind all event listeners
    channel.unsubscribe(); // Unsubscribe from the channel
  };
}, []); // Removed productAlert from the dependency array



const {user,token,setToken,setUser,setType,type,items,setItems,setUserId}=useStateContext();
const [isOffcan,setIsOffcan]=useState(false);
const stringitems = JSON.parse(items);
const [pos,setPos] = useState(localStorage.getItem('pos') || '0');

const handleOffcanClose=(data)=>{

  setIsOffcan(data);
  
  }
  
  const handleSubmitOffcan=()=>{

setIsOffcan(true);

  }

const submitLogout=()=>{

setToken(null);
setUser(null);
setType(null);
setItems(null);
setUserId(null);


}
const handlePosSetting=()=>{
  
  
  // Toggle 'pos' between 0 and 1
  const toggledPos = pos === '1' ? '0' : '1';
  if(toggledPos==='0')
  {
   
   
  
     
    
    toast.success('Switching To Thermal Printer', {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
      });
  }else{
    
    toast.success('Switching To Invoice Printer', {
      position: "top-left",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
      });
  }
  // Update the value of 'pos' in local storage
  setPos(toggledPos);
  localStorage.setItem('pos', toggledPos);
}


if(!token){

return <Navigate to='/login'/>

}
if(type==='Salesman')
{

  return (

<div className='salesman'>
  <div className='salesman-navbar'>

   

    
<div className='icons'>
  
  <div className='iconsfont'>
  <Link to={'reports'} style={{color:'white'}}>
  <FaCalendarDay/>
  </Link>
  </div>
  
</div>
<div className='icons'>
<div className='iconsfont'>
  <FaUserSecret/>

  </div>
</div>
<div className='icons'>
<div className='iconsfont'>
 <Link to={'/'} style={{color:'white'}}>
  <FaTh/>
  </Link>
  </div>
</div>

<div className='icons'>

<div className='iconsfont'>
<Link to={'billdetail'} style={{textDecoration:'none',textDecorationColor:'none',color:'#ffff'}}>
  <FaCartPlus style={{fontSize:'27px'}} /> 
  </Link>
  <Badge bg="danger" style={{color:'white',fontSize:'10px',borderRadius:'100%',zIndex:'999',position:'absolute'}}>{stringitems?stringitems.length:'0'}</Badge>
  
  </div>
</div>
<div className='icons'>
<div className='iconsfont'>
  <FaSignOutAlt onClick={submitLogout}/>

  </div>
</div>
  </div>


<Outlet/>



</div>

  )
}

  return (
   <header>
<ToastContainer
position="top-right"
autoClose={5000}
hideProgressBar={false}
newestOnTop={false}
closeOnClick
rtl={false}
pauseOnFocusLoss
draggable
pauseOnHover
theme="light"
/>
{/* Same as */}
<ToastContainer />
    <div id='row' style={{boxShadow:'0,0,2px,2px,gray'}} >
    <div className='row' style={{justifyContent:'space-around',backgroundColor:'#1d1d70',color:'#fff',paddingTop:'8px',paddingBottom:'8px',paddingRight:'30px',paddingLight:'30px',borderRadius:'2px'}}>


<div className='col-10 ' style={{display:'flex'}}> <FaHome onClick={handleSubmitOffcan} className='floating-button color-changing '  style={{color:'yellow'}}></FaHome> <Link style={{textDecoration:'none',color:'white'}} to={'/'}> <div  style={{marginLeft:'6px',marginTop:'4px',cursor:'pointer'}}>Dashboard ({user}-{type})</div></Link></div>


<div className='col-2' >
<div className='row' style={{alignItems:'center'}}>
  
<div className='col-4'>
  <Switch color="info" checked={pos==='0'?false:true} onClick={handlePosSetting}/>
  </div>
  <div className='col-4'>
    <div className="position-absolute top-0 start-90  badge rounded-pill bg-danger">
    <span style={{fontSize:'10px'}} >
      {productcount}
        </span>
    </div>
    <NavDropdown title={<FaBell style={{color:'yellow'}}/>} id="basic-nav-dropdown">
             
             {productAlert.slice().reverse() && productAlert.slice().reverse().map((item,index)=>(
                  
                           <NavDropdown.Item key={item.id}>
                          
                           
                           <Link to={'/add/product'} style={{cursor:'pointer'}} title={item.productName} key={item.id}>
               {item.productName} left qty={item.qty} items
               </Link>
             
                        
                        
                             </NavDropdown.Item>
                             ))}
                              
                          
                          
                        
                         </NavDropdown>
  </div>
  
  
  <div className='col-4'>
  <NavDropdown title={<FaPowerOff/>} id="basic-nav-dropdown">
             
              <NavDropdown.Item onClick={submitLogout}>
                Logout
              </NavDropdown.Item>
             
           
            </NavDropdown>
  </div>
</div>



       

            

</div>


</div>
<div className='row' >

<Outlet/>


</div>

      
    </div>
    
    {type==='Salesman'?
'':
isOffcan && <Sidebar show={isOffcan} onChildOffcanClose={handleOffcanClose}></Sidebar>

  }
    </header>
  )






}

export default DefaultLayout
