import React, { useCallback, useEffect, useState } from 'react';
import axios from 'axios';


const ImportProducts = () => {
    const [file, setFile] = useState(null);
const[batchId,setBatchId]=useState('');
const[progress,setProgess]=useState(1);
const [error,setError]=useState('');
const[isLoading,setIsLoading]=useState(false);
    const handleFileChange = (e) => {
        setFile(e.target.files[0]);
    };

    const handleSubmit = async (e) => {
        setIsLoading(true);
        e.preventDefault();

        const formData = new FormData();
        formData.append('file', file);

        try {
            const response = await axios.post('api/import/products', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });

            //console.log('Response:', response.data);
            setBatchId(response.data.batchId);
            setIsLoading(false);
            setError('');
            // Handle success response
        } catch (error) {
            console.error('Error uploading file:', error);
            setError(error.message);
            setIsLoading(false);
            // Handle error
        }
    };
    const updateProgress = useCallback(() => {
        axios.get(`api/batch/${batchId}`).then(response => {
          setProgess(response.data.progress);
        });
      }, [batchId]); // useCallback with batchId as dependency
      
      useEffect(() => {
        const fetchData = async () => {
          if (batchId || progress > 1) {
             updateProgress();
          }
        };
      
        fetchData();
      }, [batchId, progress, updateProgress]); // Include updateProgress in useEffect dependencies
      



    return (

        <div  style={{textAlign:'center',marginTop:'10%'}}>
{error && (

    <h1 style={{color:'red'}}>{error}</h1>
)}

{progress===100 &&(
    <h1>Task completed sucessfully</h1>)}

{batchId && (
   <div style={{backgroundColor:'lightgreen',color:'black',padding:'10px'}}> 
<div className="progress">
  <div className="progress-bar" role="progressbar" style={{width:`${progress}%`}} aria-valuenow={progress} aria-valuemin="0" aria-valuemax="100">{progress}%</div>
</div>

</div>
      )}  

      {!batchId&&(
        <form onSubmit={handleSubmit}>
            <input type="file" onChange={handleFileChange} />
            <button type="submit"  className='btn btn-sm btn-primary'>{isLoading?'Please Wait...':'Upload File'}</button>
        </form>
        )}
        
        </div>
    );
};

export default ImportProducts;
