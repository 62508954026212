

import React, {  useEffect, useRef, useState } from 'react'
import {   FaTrash } from 'react-icons/fa';

import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { Row } from 'react-bootstrap';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import Form from 'react-bootstrap/Form';
import   '../Customcss/saleEntry.css';
import { Box } from '@mui/system';

import axios from 'axios';
import Puff from 'react-loading-icons/dist/esm/components/puff';
import PrintPurchaseInvoice from './PrintPurchaseInvoice';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';




function PurchaseEntryInvoice() {
    const [results,setresutls]=useState([]);
    const [items, setItems] = useState([]);
    const [productid, setProductId] = useState(null);
    const inputRef = useRef(null);
    const [manualdate,setManualDate]=useState('');
    const autocompleteRef = useRef(null);
    const [distributer,setDistributer]=useState([]);
    const [defaultdistributer,setDefaultDistributer]=useState('');
    const [totalwithoutgst,setTotalWithoutGst]=useState(0);
    const [totalwithgst,setTotalWithGst]=useState(0);
    const [totaldiscount,setTotalDiscount]=useState(0);
    const [totalwholesalemargin,setTotalWholesaleMargin]=useState(0);
    const [totalretailmargin,setTotalRetailMargin]=useState(0);
    const [totalgst,setTotalGst]=useState(0);
    const [netamount,setNetAmount]=useState(0);
    const [remaining,setRemaining]=useState(0);
    const [totalpaid,setTotalPaid]=useState(0);
    const [isClick,setIsClick]=useState(false);
    const [isPrint,setIsPrint]=useState(false);
    const [printData,setPrintData]=useState([]);


useEffect(()=>{

  const totalwholesalemargin = items.reduce((acc, item) => {
    const value = parseFloat(item.totalwholesalemargin);
    return isNaN(value) ? acc : acc + value;
  }, 0);
  setTotalWholesaleMargin(totalwholesalemargin.toFixed(2));

  const totalretailmargin = items.reduce((acc, item) => {
    const value = parseFloat(item.retailmargin);
    return isNaN(value) ? acc : acc + value;
  }, 0);
  setTotalRetailMargin(totalretailmargin.toFixed(2));


  const totaldiscount = items.reduce((acc, item) => {
    const value = parseFloat(item.totaldiscount);
    return isNaN(value) ? acc : acc + value;
  }, 0);
  setTotalDiscount(totaldiscount.toFixed(2));


  const total = items.reduce((acc, item) => {
    const value = parseFloat(item.qty*item.unitcostprice);
    return isNaN(value) ? acc : acc + value;
  }, 0);
  setTotalWithoutGst((total).toFixed(2));

  


  const totalgst = items.reduce((acc, item) => {
    const value = parseFloat(item.gst);
    return isNaN(value) ? acc : acc + value;
  }, 0);
  setTotalGst(totalgst.toFixed(2));

  setTotalWithGst((total+totalgst).toFixed(2));

setNetAmount(((total-totaldiscount+totalgst)-totalwholesalemargin-totalretailmargin).toFixed(2));
setRemaining((((total-totaldiscount+totalgst)-totalwholesalemargin-totalretailmargin)-totalpaid).toFixed(2))
},[items,items.qty,totalpaid])

useEffect(()=>{

axios.get(`/api/fetch/distributer`).then(res=>{
setDistributer(res.data.user);
setDefaultDistributer(res.data.user[0]['id']);


});

},[])

const handleKeyDown = (e) => {
  
  if (e.key === "Enter") {
    if(autocompleteRef.current){
      autocompleteRef.current.focus();

    }
  }
};
const handleSubmit = (val) => {
  
  

    axios.get(`/api/fetchitem/${val.id}`).then(res=>{
      if (items.find((i) => i.id === res.data.item.id)) {
      
        toast.error('Item Already Added!', {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          });
        
    }else{
    
const extra={
'discount':0,
'totaldiscount':0,
'gst':0,
'wholesalemargin':4.28,//place here 4.28
'totalwholesalemargin':((res.data.item.unitcostprice)*4.28/100).toFixed(2),
'retailmargin':0


}

      setItems([
        ...items,
        { ...res.data.item,pack_qty:parseFloat(1/res.data.item.pack_size).toFixed(2), qty: 1,...extra }
      ]);

      setProductId(null);
      
    }
    });
  
  
    
    
   
    

};

const   handleRemove = (id) => {
    
  setItems(items.filter((item) => item.id !== id));
}; 



const handleQtyChange = (id, event) => {
  const newItems = [...items];
  const index = newItems.findIndex(item => item.id === id);
  
  newItems[index].qty = event.target.value;
  
newItems[index].totaldiscount=newItems[index].qty*newItems[index].discount;

newItems[index].totalwholesalemargin=((newItems[index].unitcostprice*newItems[index].qty-newItems[index].totaldiscount)*(newItems[index].wholesalemargin/100)).toFixed(2);
newItems[index].pack_qty=(newItems[index].qty/newItems[index].pack_size).toFixed(2);
  if(event.target.value >=1){
    setItems(newItems);

  }
  else{
    return alert('less than 0 not allowed');
  }
}
const handlePackQtyChange = (id, event) => {
  const newItems = [...items];
  const index = newItems.findIndex(item => item.id === id);
   newItems[index].pack_qty = event.target.value;
   newItems[index].totaldiscount=(newItems[index].pack_qty*newItems[index].pack_size)*newItems[index].discount;
   newItems[index].totalwholesalemargin=((newItems[index].unitcostprice*(parseFloat(parseFloat(newItems[index].pack_qty)*parseFloat(newItems[index].pack_size)).toFixed(2))-newItems[index].totaldiscount)*(newItems[index].wholesalemargin/100)).toFixed(2);
   newItems[index].qty=(newItems[index].pack_qty*newItems[index].pack_size).toFixed(2);
  if(event.target.value >=0){
    setItems(newItems);

  }
  else{
    return alert('less than 0 not allowed');
  }
};



const handleDiscountChange = (id, e) => {
  const newItems = [...items];
  const index = newItems.findIndex(item => item.id === id);
if(e.target.value>=0){
  newItems[index].discount = e.target.value;
 //uncomment to put formula for salman traders
  newItems[index].totaldiscount=(newItems[index].qty*e.target.value).toFixed(2);
 //this one line for percent formula
 newItems[index].totaldiscount=(((newItems[index].unitcostprice*newItems[index].qty)*e.target.value/100)).toFixed(2);


 //above this
 
  newItems[index].totalwholesalemargin=((newItems[index].unitcostprice*newItems[index].qty-newItems[index].totaldiscount)*(newItems[index].wholesalemargin/100)).toFixed(2);
   
  setItems(newItems);
}else{
alert('less than zero is not allowed');
}
  
  
 
};

const handleWholesaleMarginChange = (id, e) => {
  const newItems = [...items];
  const index = newItems.findIndex(item => item.id === id);
if(e.target.value>=1){
  newItems[index].wholesalemargin=e.target.value;
  newItems[index].totalwholesalemargin=(((newItems[index].qty*newItems[index].unitcostprice)*e.target.value/100).toFixed(2));
  
setItems(newItems);
}else{
alert('less than zero is not allowed');
}
   
  
 
};

const handleGstChange=(id,e)=>{
const newItems=[...items];
const index=newItems.findIndex(item=>item.id===id);
newItems[index].gst=e.target.value;
setItems(newItems);


}
const handleRetailmarginChange=(id,e)=>{
  const newItems=[...items];
  const index=newItems.findIndex(item=>item.id===id);
  newItems[index].retailmargin=e.target.value;
  setItems(newItems);


}
const handleUnitCostPriceChange = (id, event) => {
  const newItems = [...items];
  const index = newItems.findIndex(item => item.id === id);
  newItems[index].unitcostprice = event.target.value;

  
  
  newItems[index].totaldiscount=newItems[index].qty*newItems[index].discount;
  
  newItems[index].totalwholesalemargin=((newItems[index].unitcostprice*newItems[index].qty-newItems[index].totaldiscount)*(newItems[index].wholesalemargin/100)).toFixed(2);
  
    if(event.target.value >=0){
      setItems(newItems);
  
    }
    else{
      return alert('less than 1 not allowed');
    }


  setItems(newItems);
};
useEffect(()=>{

  axios.get(`/api/fetch/stock/for/purchase/entry`).then(res=>{
      setresutls(res.data.products);
  
       
    });

},[])

useEffect(() => {
 
  if (inputRef.current) {

    inputRef.current.focus();
  }
},[items.length]);

const handleSaveReceipt=()=>{
setIsClick(true);
const data={
letter:items,
ppcell:defaultdistributer,
manualdate:manualdate,
totalwholesalemargin:totalwholesalemargin,

totaldiscount:totaldiscount,
totalgst:totalgst,
supergrandtotal:totalwithoutgst,
cash:totalpaid,
totalretailmargin:totalretailmargin

}

axios.post('api/save/purchase/invoice',data).then(res=>{

const printdatas={
cart:res.data.cart,
dates:res.data.dates,
printingtime:res.data.printingtime,
totalgst:res.data.salestax,
totalwholesalemargin:res.data.wsmargin,
totaldiscount:res.data.discount,
totalretailmargin:res.data.totalretailmargin,
totalwithgst:res.data.grandtotal,
invoiceno:res.data.invoiceno,
customer:res.data.customer,
totalpaid:res.data.payment,
pbalance:res.data.pbalance,

detail:res.data.detail


}


  setPrintData(printdatas);
      
      
  setIsPrint(true);

setIsClick(false);

});
}
useEffect(()=>{


  if(isPrint){

 
    var div = document.getElementById('thermalpospurcahsecosmetics');

    // Create a window object.
    var win = window.open('', '', 'height=700,width=700'); // Open the window. Its a popup window.
    win.document.write(div.outerHTML);     // Write contents in the new window.
    win.document.close();
    
  setIsPrint(false);
  setItems([]);
  }

},[isPrint]);

  return (
    <div className='row'>

    
    <div className='fadeInDown animated' style={{margin:'12px'}}>
     
<Row className='g-3'>

    <div className='col-md-2'>
    <FloatingLabel controlId="categoryname" label="Manual Date">
        <Form.Control type="date" value={manualdate} onChange={(e)=>setManualDate(e.target.value)} name="manualdate" placeholder="ManudalDate" />
      </FloatingLabel>
    </div>

    <div className='col-md-3'>
    <FloatingLabel  label="Select Distributer">
      <Form.Select value={defaultdistributer} onChange={(e)=>setDefaultDistributer(e.target.value)} aria-label="Select Distributer" name='distributer' >
      {distributer && distributer.map(item => (
       
        <option key={item.id} value={item.id}>
          {item.name} </option>))}
       </Form.Select>
    </FloatingLabel>
    </div>
    <div className='col-md'>
  
  

    
<Autocomplete
  disablePortal
  id="combo-box-demo"
  
  value={productid}
  onChange={(event, newValue) => {
    setProductId(newValue);
    handleSubmit(newValue);


  }}
  
  getOptionLabel={(results) => `${results.name}`}
  isOptionEqualToValue={(option, value) => option.name === value.name}
  options={results}

  noOptionsText={"No Item Found"}
  renderOption={(props,results)=>(
<Box component="li" {...props} key={results.id}>
{results.name}
</Box>

  )}
  renderInput={(params) => (
    <TextField     {...params} label={productid?<Puff stroke='red'/>:'Search Item'} variant="outlined" className='form-control'  inputRef={autocompleteRef} />
  )}
 
  
/>
  
      
    
  </div> 

</Row>

<Row className='g-1 cartBarHeading mt-2 p-2' style={{borderRadius:'4px',textAlign:'center'}}>

<div className='col-1'>

Name
</div>
<div className='col-1'>
Pack/Ctn
</div>
<div className='col-1'>
Pieces
</div>
<div className='col-1'>
Weight
</div>

<div className='col-1'>
Rate
</div>


<div className='col-1'>
Total
</div>

<div className='col-2'>
Discount & Total
</div>
<div className='col-2'>
WholeSale & Margin 
</div>
<div className='col-1'>
GST & R/M
</div>
<div className='col-1'>
Remove
</div>

</Row>
<div style={{borderRadius:'4px',border:'1px solid lightgray',textAlign:'center',height:'50vh',overflowY:'auto',overflowX:'hidden'}}>

<div>
  {defaultdistributer?'':<Puff stroke='red' height={100} width={100}/>}
</div>
{items && items.map((item,index)=>(


<Row key={item.id} className='g-1 cartbody mt-0 p-1 animated fadeInDown' style={{borderRadius:'4px',borderTop:'1px solid lightgray',textAlign:'center'}}>


<div className='col-1'>

<TextField
          label="Name"
          value={item.name}
         name='name'
          size="small"
        />
</div>
<div className='col-1'>
<TextField
inputRef={index === items.length - 1 ? inputRef : null}
          label={item.unit}
         value={item.pack_qty}
         onChange={(e)=>handlePackQtyChange(item.id,e)}
         name='pack_qty'
         type='number'
          size="small"
        />
</div>
<div className='col-1' >
<TextField type='number'  label="qty"
         name='qty' size='small'  onKeyDown={handleKeyDown}   style={{textAlign:'center'}} className='form-control' value={item.qty} onChange={event => handleQtyChange(item.id, event)}></TextField>

</div>
<div className='col-1'>
<TextField
          label="weight"
         name='weight'
         value={item.weight}
       
          size="small"
        />
</div>

<div className='col-1'>
<TextField
          label="Rate"
         value={parseFloat(item.unitcostprice).toFixed(2)}
         name='unitcostprice'
         onChange={event => handleUnitCostPriceChange(item.id, event)}
          size="small"
          type='number'
          onKeyDown={handleKeyDown}
        />
</div>


<div className='col-1'>
<TextField
          label="Total"
        name='total'
        value={parseFloat(item.unitcostprice*item.qty).toFixed(2)}
       disabled
          size="small"
        />
</div>

<div className='col-1'>
<TextField
          label="Discount"
       onChange={(e)=>handleDiscountChange(item.id,e)}
        value={item.discount}
        type='number'
          size="small"
          onKeyDown={handleKeyDown}
        />
</div>
<div className='col-1'>
<TextField
          label="Total"
          value={item.totaldiscount}
         disabled
          size="small"
        />
</div>
<div className='col-1'>
<TextField
          label="w-mgn"
         value={item.wholesalemargin}
         onChange={(e)=>handleWholesaleMarginChange(item.id,e)}
          size="small"
          name='wholesalemargin'
          type='number'
          onKeyDown={handleKeyDown}
        />

       
</div>
<div className='col-1' >
<TextField
          label="Total"
         value={item.totalwholesalemargin}
         disabled
         name='totalwholesalemargin'
          size="small"
        />


       
</div>

<div className='col-2' style={{display:'flex'}}>
<TextField
          label="GST"
         name='gst'
         value={item.gst}
         onChange={(e)=>handleGstChange(item.id,e)}
         type='number'
          size="small"
          onKeyDown={handleKeyDown}
        />
<TextField
          label="Retail"
       name='retailmargin'
value={item.retailmargin}
type='number'
onChange={(e)=>handleRetailmarginChange(item.id,e)}
         
          size="small"
          onKeyDown={handleKeyDown}
        />
<FaTrash style={{color:'red',fontSize:'40px',marginTop:'-5px',marginLeft:'2px',borderBottom:'1px solid gray'}} onClick={() => handleRemove(item.id)}/>

</div>

</Row>

))}


</div>



<Row className='g-1 p-1 mt-1 ' style={{borderRadius:'4px',border:'1px solid lightgray '}}>

<div className='col-2'>
<TextField
          label="G.Total WGST"
        value={totalwithgst}
        onChange={(e)=>setTotalWithGst(e.target.value)}
         name='totalwithgst'
          size="small"
          type='number'
          disabled

        />
</div>
<div className='col-2'>
<TextField
          label="G.Total WOGST"
       value={totalwithoutgst}
         onChange={(e)=>setTotalWithoutGst(e.target.value)}
          size="small"
          type='number'
          disabled
        />
</div>
<div className='col-1'>
<TextField
          label="Discount Amount"
          value={totaldiscount}
          onChange={(e)=>setTotalDiscount(e.target.value)}
          type='number'
         
          size="small"
          disabled
        />
</div>
<div className='col-1'>
<TextField
          label="Wholesale Margin"
          value={totalwholesalemargin}
          onChange={(e)=>setTotalWholesaleMargin(e.target.value)}
          type='number'

          size="small"
          disabled
        />

        
</div>
<div className='col-1'>
<TextField
          label="Retail Margin"
          value={totalretailmargin}
          onChange={(e)=>setTotalRetailMargin(e.target.value)}
         type='number'
          size="small"
          disabled
        />
</div>

<div className='col-1'>
<TextField
          label="Sales Tax"
          value={totalgst}
          onChange={(e)=>setTotalGst(e.target.value)}
         type='number'
          size="small"
          disabled
        />
</div>
<div className='col-2'>
<TextField
          label="Paid Amount"
          value={totalpaid}
          onChange={(e)=>setTotalPaid(e.target.value)}
        type='number'
          color="success"
          size="small"
          focused
        />
</div>
<div className='col-1'>
<TextField
          label="Net Amount"
          value={netamount}
          onChange={(e)=>setNetAmount(e.target.value)}
         disabled
          size="small"
          
        />
</div>
<div className='col-1'>
<TextField
          label="Remainng"
          
          value={remaining}
          onChange={(e)=>setRemaining(e.target.value)}
          size="small"
         
          disabled
         
        />
</div>
</Row>

<Row className='g-2 p-1 mt-1' style={{backgroundColor:'whitesmoke',borderRadius:'4px'}}>
<div className='col-md'>
<button className='btn btn-primary btn-lg btn-block' onClick={handleSaveReceipt}> {isClick? <Puff width={25} height={25} />:'Save & Print'} </button>
</div>
<div className='col-md'>
<Link className='btn btn-warning btn-lg btn-block' to='/'>Dashboard</Link>
</div>

</Row>

</div>

<div style={{ display: 'none' }} >
{
  
  isPrint  && <PrintPurchaseInvoice data={printData} />
  
  
  }




 
 
</div>
    </div>
  )
}

export default PurchaseEntryInvoice
